import Vue from 'vue';
import VueI18n from 'vue-i18n';
import zhTW_Locale from './locales/zh-TW';

Vue.use(VueI18n);

const messages = {
  tw: {
    ...zhTW_Locale,
  },
};

const locales = Object.keys(messages);
const locale = locales[0];

export const i18n = new VueI18n({
  messages,
  locale,
});

export default i18n;
