<template>
  <div id="app">
    <Olympic />
  </div>
</template>
<script>
// import Champions from '@/views/Champions.vue';
import Olympic from '@/views/Olympic.vue';

export default {
  components: {
    // Champions,
    Olympic
  },
};
</script>

<style >
html {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 13px;
  font-family: Arial, ReviseText, Microsoft JhengHei, Microsoft YaHei, sans-serif;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

#app {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
</style>
