<template>
  <main
    class="champions"
    :class="{ mobile: windowWidth < breakWidth, pad: windowWidth < padWidth }"
  >
    <div style="position: fixed; z-index: -2"
      >-巴黎奧運倒數計時-準備好迎接全球最盛大的體育盛事了嗎？2024年7月26日至8月11日，讓我們一起見證歷史！
      -奧運熱門賽事- 羽球：關注小戴是否能在羽毛球場上稱霸？
      桌球：快速的旋轉與反應，桌球對決中誰將奪冠？ 網球：力與巧的對抗，是否能在場上得勝？ -轉播資訊
      - 不要錯過任何一刻精彩！收看我們的轉播，享受奧運賽事的每一秒！
      -中華隊加油-支持我們的選手，為他們喝彩！中華隊，向前衝！</div
    >
    <div class="champions_bg" />
    <header>
      <div class="nav_bar">
        <img src="@/assets/olympicPic/bt_home.png" alt="" />
        <img src="@/assets/olympicPic/s1_logo.png" alt="" />
      </div>
    </header>
    <img class="bt_top" src="@/assets/olympicPic/bt_top.png" alt="" @click="scrollToTop" />
    <section class="champions_section s1">
      <img class="section_content" :src="s1img" />
      <div v-show="countDown >= 0" class="time_block">
        <div class="time_block-content">
          <div>開賽倒數</div>
          <div class="count-day">{{ toCountdownText(countDown) }}</div>
        </div>
      </div>
    </section>
    <section class="champions_section ss">
      <img class="section_title" src="@/assets/olympicPic/steps-title.png" />
      <img class="section_content" src="@/assets/olympicPic/steps.gif" />
      <div class="message"
        >活動期間 2024/07/22-2024/8/11 至 【奧運猜金牌】 專區投注支持的隊伍及選手，
        單項比賽項目最高可投注1萬元</div
      >
    </section>
    <section class="champions_section s2">
      <div class="s2-title">賽程表</div>
      <div class="section_content cup1">
        <div class="groups">
          <div
            v-for="(group, key) in groups1"
            :key="key"
            class="group"
            :class="{ active: activeGroup1 === key }"
            @click="setActiveGroup1(key)"
            >{{ $t(`olympic.${key}`) }}</div
          >
        </div>
        <div class="events">
          <div v-if="windowWidth > breakWidth" class="title">
            <div class="desktop">時間</div>
            <div class="desktop">項目</div>
            <div class="desktop">對戰隊伍</div>
            <div v-if="['badmin', 'pinpon', 'tennis'].includes(activeGroup1)" class="desktop member"
              >球員</div
            >
          </div>
          <div v-for="(item, key) in groups1[activeGroup1]" :key="key" class="info">
            <template v-if="windowWidth > breakWidth">
              <div class="desktop">{{ item[0] }}</div>
              <div class="desktop">{{ item[1] }}</div>
              <div class="desktop">{{ item[2] }}</div>
              <div v-if="item[3]" class="desktop member">{{ item[3] }}</div>
            </template>
            <template v-else>
              <div class="info_mob-title">
                <div>{{ item[0] }}</div>
                <div>{{ item[1] }}</div>
              </div>
              <div class="country">{{ item[2] }}</div>
              <div v-if="item[3]" class="member">{{ item[3] }}</div>
            </template>
          </div>
        </div>
        <div class="bet-contain">
          <img src="@/assets/olympicPic/bt.png" alt="" class="bet" />
        </div>
      </div>
    </section>
    <section class="champions_section s3">
      <img class="section_content" src="@/assets/olympicPic/bn_live.png" />
      <img class="bt_live" src="@/assets/olympicPic/bt_live.png" alt="" />
    </section>
    <footer>
      <img src="@/assets/olympicPic/bg_footer.png" alt="" />
    </footer>
  </main>
</template>

<script>
  import Papa from 'papaparse';

  const csvUrl = 'https://img.q1688.net/agent-img';
  const csvRandom = new Date().getTime();
  const csvConfig = {
    download: true,
    header: false,
    dynamicTyping: true,
    skipEmptyLines: true,
    comments: '#',
  };

  export default {
    data() {
      return {
        windowWidth: 1024,
        padWidth: 1250,
        breakWidth: 640, // 手機斷點
        olympicStratTime: new Date('2024-07-26').getTime(),
        // olympicStratTime:new Date('2024-06-25T11:29:00').getTime(),
        countDown: 0,
        countdownInteral: null,
        showCountDown: true,
        activeCup: 1,
        activeGroup1: 'badmin',
        groups1: {
          badmin: [['germany', 0, 0, 0, 0, 0, 0, 0, 0]],
          basket: [['spain', 0, 0, 0, 0, 0, 0, 0, 0]],
          socer: [['slovenia', 0, 0, 0, 0, 0, 0, 0, 0]],
          pinpon: [['poland', 0, 0, 0, 0, 0, 0, 0, 0]],
          tennis: [['belgium', 0, 0, 0, 0, 0, 0, 0, 0]],
          vollyball: [['turkey', 0, 0, 0, 0, 0, 0, 0, 0]],
        },
      };
    },
    computed: {
      s1img() {
        if (this.countDown <= 0) {
          this.showCountDown = false;
          if (this.windowWidth < this.breakWidth)
            return require('@/assets/olympicPic/homebanner_mob2.png');
          return require('@/assets/olympicPic/homebanner2.png');
        } else {
          if (this.windowWidth < this.breakWidth)
            return require('@/assets/olympicPic/homebanner_mob.png');
          return require('@/assets/olympicPic/homebanner.png');
        }
      },
    },
    methods: {
      scrollToTop() {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      },
      setActiveGroup1(group) {
        this.activeGroup1 = group;
        Papa.parse(`${csvUrl}/olympic/${group}.csv?=${csvRandom}`, {
          ...csvConfig,
          complete: (res) => {
            this.groups1[group] = res.data;
            // this.groups1[group] = results.data.sort((row1, row2) => row2[8] - row1[8]);
            // this.groups1Loaded[group] = true;
          },
        });
      },
      updateWindowWidth() {
        this.windowWidth = window.innerWidth;
      },
      toCountdownText(diff) {
        if (diff > 0) {
          const hourSpanTime = 1000 * 60 * 60;
          const minSpanTime = 1000 * 60;
          const daySpanTime = 24 * 60 * 60 * 1000;
          const days = Math.floor(diff / daySpanTime);
          if (days > 0) {
            diff -= days * daySpanTime;
          }
          let hours = Math.floor(diff / hourSpanTime);
          if (hours > 0) {
            diff -= hours * hourSpanTime;
            if (hours < 10) {
              hours = '0' + hours;
            }
          }
          let mins = Math.floor(diff / minSpanTime);
          if (mins > 0) {
            diff -= mins * minSpanTime;
            if (mins < 10) {
              mins = '0' + mins;
            }
          }
          let seconds = Math.floor(diff / 1000);
          if (seconds > 0) {
            diff -= seconds * 1000;
            if (seconds < 10) {
              seconds = '0' + seconds;
            }
          }
          return `${days}日 ${hours}時 ${mins}分 ${seconds}秒`;
        }
        return `0日 00時 00分 00秒`;
      },
    },
    mounted() {
      window.addEventListener('resize', this.updateWindowWidth);
      this.updateWindowWidth();
      this.setActiveGroup1('badmin');
      this.countdownInteral = setInterval(() => {
        const now = new Date().getTime();
        this.countDown = this.olympicStratTime - now;
      }, 1000);
    },
    beforeDestroy() {
      if (this.countdownInteral) {
        clearInterval(this.countdownInteral);
        this.countdownInteral = null;
      }
    },
    destroyed() {
      window.removeEventListener('resize', this.updateWindowWidth);
    },
  };
</script>

<style lang="scss" scoped>
  .champions {
    position: relative;
    width: 100%;
    line-height: 1.4vw;
    overflow-x: hidden;
    color: #fff;
    font-size: 1.4vw;
    word-break: keep-all;
    user-select: none;

    ::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    ::-webkit-scrollbar:vertical {
      width: 4px;
    }

    ::-webkit-scrollbar:horizontal {
      height: 4px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background-color: rgba(0, 0, 0, 0.3);
    }

    > .champions_bg {
      position: fixed;
      width: 100%;
      height: 100%;
      background-image: url('~@/assets/olympicPic/bg.jpg');
      background-size: cover;
      overflow: hidden;
      z-index: -1;
    }

    .nav_bar {
      width: 100%;
      position: absolute;
      z-index: 20;
      display: flex;
      align-items: center;
      padding: 10px;
      background-color: rgba(0, 0, 0, 0.7);
      img + img {
        margin-left: 5px;
      }
    }

    .bt_top {
      position: fixed;
      z-index: 10;
      right: 5px;
      top: 80vh;
      cursor: pointer;
    }

    > .champions_section {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      position: relative;
      width: 100%;
      margin-bottom: 4vw;

      > .section_content {
        position: relative;
        width: 52.08vw;
        margin-bottom: 1vw;
      }

      &.s1 {
        > .section_content {
          width: 100%;
        }
        .time_block {
          width: 450px;
          height: 190px;
          position: absolute;
          right: 50%;
          top: 30vw;
          background: url('~@/assets/olympicPic/bg_timedt.png') no-repeat;
          .count-day {
            font-size: x-large;
          }
          &-content {
            position: absolute;
            right: 0;
            top: 30%;
            width: 250px;
            div + div {
              margin-top: 16px;
            }
          }
        }
      }

      &.s2 {
        margin-bottom: 8vw;
        .s2-title {
          width: 590px;
          height: 90px;
          position: absolute;
          z-index: 11;
          top: -30px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 30px;
          background: url('~@/assets/olympicPic/title.png') no-repeat;
          background-size: contain;
        }

        > .section_content {
          width: 72vw;
          min-width: 360px;
          padding-left: max(4px, 4vw);
          padding-right: max(4px, 4vw);
          padding-top: max(8px, 5vw);
          padding-bottom: max(8px, 2vw);
          border-radius: max(8px, 2vw);
          box-shadow: 0 0.6vw 2.4vw 0 rgba(0, 0, 0, 0.5);

          &.cup1 {
            background-image: linear-gradient(to bottom, #f6e6a5, #e2cc75);
          }

          > .groups {
            overflow-x: scroll;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            margin-bottom: max(6px, 1vw);

            > .group {
              padding-left: max(10px, 4vw);
              padding-right: max(10px, 4vw);
              padding-top: max(6px, 1vw);
              padding-bottom: max(4px, 1vw);
              margin-left: 5px;
              border-width: max(1px, 0.2vw);
              border-style: solid;
              border-color: #a68919;
              border-radius: max(8px, 2vw);
              color: #a68919;
              font-size: max(13px, 1.6vw);
              font-weight: 400;
              cursor: pointer;

              &:hover {
                background-color: #a68919;
                color: #fff4ca;
              }

              &.active {
                // border-color: transparent;
                // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                background-color: #a68919;
                color: #fff4ca;
                border-color: #e3d185;
              }
            }
          }

          .events {
            max-height: 600px;
            overflow-y: scroll;
            ::-webkit-scrollbar:horizontal {
              height: max(2px, 1vw);
            }

            ::-webkit-scrollbar-thumb {
              border-radius: max(2px, 1vw);
              background-color: rgba(0, 0, 0, 0.2);
            }
            .title,
            .info {
              padding: 0.5vw 0;
              display: flex;
              justify-content: space-around;
              background-color: #bca23d;
              .desktop {
                flex: 1;
                text-align: center;
              }
              .member {
                flex: 2;
              }
            }
            .title {
              position: sticky;
              top: 0;
            }
            .info {
              padding: 1vw 0;
              color: #703703;
              border-bottom: #fff 1px solid;
              background-color: #f8eaaf;
            }
          }

          .bet-contain {
            width: 100%;
            text-align: center;
          }

          .bet {
            width: 140px;
            margin-top: max(10px, 2vw);
            text-align: center;
            cursor: pointer;
            &:hover {
              transform: scale(1.1);
            }
          }
        }
      }

      &.s3 {
        > .section_content {
          width: 70%;
        }
        .bt_live {
          width: 20%;
          position: absolute;
          bottom: 0;
          cursor: pointer;
          &:hover {
            transform: scale(1.1);
          }
        }
      }

      &.ss {
        > .section_title {
          width: 32%;
        }
        > .section_content {
          width: 80%;
        }
        > .message {
          padding: 16px;
          font-size: 24px;
          line-height: 32px;
          text-align: center;
        }
        margin-bottom: 10vw;
      }
    }
    footer {
      display: flex;
      img {
        width: 100%;
        flex: 1;
      }
    }

    &.pad {
      .champions_section {
        &.s1 {
          .time_block {
            width: 250px;
            height: 90px;
            position: absolute;
            right: 50%;
            top: 28vw;
            background: #ea92bc;
            background-size: contain;
            .count-day {
              font-size: large;
            }
            &-content {
              position: absolute;
              right: 0;
              top: 30%;
              width: 220px;
              div + div {
                margin-top: 16px;
              }
            }
          }
        }
        &.s2 {
          .s2-title {
            height: 7vw;
            position: absolute;
            z-index: 11;
            top: -35px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 22px;
            background: url('~@/assets/olympicPic/title.png') no-repeat;
            background-size: contain;
            background-position: center;
          }
        }

        &.ss {
          > .section_title {
            width: 40%;
          }
          > .section_content {
            width: 80%;
          }
          > .message {
            font-size: 20px;
            line-height: 24px;
          }
          margin-bottom: 10vw;
        }
      }
    }

    &.mobile {
      .champions_bg {
        background-image: url('~@/assets/olympicPic/bg_mob.jpg');
      }

      .nav_bar {
        position: relative;
        background-color: #112d69;
        img {
          width: 25px;
        }
        img + img {
          width: 110px;
        }
      }

      > .champions_section {
        margin-bottom: 12vw;

        > .section_content {
          width: 76vw;
        }

        // > .section_betbutton {
        //   height: 48px;
        //   margin-bottom: 4px;
        // }

        &.s1 {
          display: block;
          margin-bottom: 16vw;

          > .section_content {
            position: relative;
            width: 100%;
          }

          .time_block {
            width: 60vw;
            height: 16vw;
            top: 53vw;
            // left:-100%;
            right: 0;
            background: #ea92bc;
            background-size: contain;
            .count-day {
              font-size: larger;
            }
            &-content {
              right: -10%;
              width: 230px;
              font-size: 16px;
              // div {
              //   font-size: 16px;
              // }
            }
          }
        }

        &.s2 {
          .s2-title {
            width: 70%;
            height: 45px;
            top: -20px;
            background-size: contain;
            font-size: 16px;
          }
          > .section_content {
            min-width: 80vw;
            padding-top: max(8px, 7vw);
            border-radius: max(8px, 6vw);
            font-size: 14px;
            line-height: 16px;

            .groups {
              overflow-x: scroll;
              .group + .group {
                margin-left: 5px;
              }
            }
            .events {
              max-height: 480px;

              .info {
                height: 80px;
                flex-direction: column;
                border-radius: 10px;
                align-items: center;
                background: #fff3c4;
                // flex-wrap: wrap;
                // flex:0;
                &_mob-title {
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                  border-bottom: 2px solid #ebdeaa;
                  div {
                    padding: 5px;
                  }
                }
                .country {
                  font-size: 16px;
                  font-weight: 600;
                  line-height: 36px;
                }
              }
              .info + .info {
                margin-top: 15px;
              }
            }

            .bet-contain {
              .bet {
                width: 100px;
              }
            }
          }
        }

        &.s3 {
          > .section_content {
            width: 90vw;
          }
          .bt_live {
            width: 50%;
            bottom: -30px;
          }
        }

        &.ss {
          > .section_title {
            width: 40%;
          }
          > .section_content {
            width: 100%;
          }
          > .message {
            font-size: 16px;
            line-height: 20px;
          }
          margin-bottom: 10vw;
        }
      }
    }
  }
</style>
